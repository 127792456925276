import { APIListResponse, compositeData, deleteData, getData, patchData, postData } from 'APIHandler';
import convertCompositeResponseToObject from 'api/convertCompositeResponseToObject';
import { generateRequestURL } from 'helpers/Utils';
import { importedContentID, learnContentID, maxInteger, workplaceID } from 'helpers/constants';
import { FeatureFlagValues } from 'pages/Admin/AdminSuperAdmin/AdminSuperAdminFeatureFlagsCreate';
import { PaginationQueryParams } from 'types';
import { FeatureFlagType } from 'types/features';
import { SmartTileType, TileType } from 'types/tile';
import { getTileChildrenByID } from './TileAPI';

export const createNewFeatureFlag = (values: Partial<FeatureFlagValues>) => {
  return postData('FeaturesAPI', 'flags', values);
};

export const updateFeatureFlag = (featureUUID: string, values: Partial<FeatureFlagValues>) => {
  return patchData('FeaturesAPI', `flags/${featureUUID}`, values);
};

export const addOrEditFeature = (featureUUID: string | undefined, values: Partial<FeatureFlagValues>) => {
  if (featureUUID) return updateFeatureFlag(featureUUID, values);
  return createNewFeatureFlag(values);
};

export const getFeatureFlags = (params: PaginationQueryParams) => {
  return getData('FeaturesAPI', generateRequestURL('flags', params));
};

export const getFeatureFlag = (featureUUID: string) => {
  return getData('FeaturesAPI', `flags/${featureUUID}`);
};

export const deleteFeatureFlag = (featureUUID: string) => {
  return deleteData('FeaturesAPI', `flags/${featureUUID}`);
};

export const getOrgsWithFeatureCompositeObj = (featureUUID: string, params: PaginationQueryParams) => {
  return {
    method: 'GET',
    url: generateRequestURL(`/features/huler/flags/${featureUUID}/organisations`, params),
    referenceId: featureUUID,
  };
};

export const getOrgsWithFeature = (featureUUID: string, params: PaginationQueryParams) => {
  return getData('FeaturesAPI', generateRequestURL(`flags/${featureUUID}/organisations`, params));
};

const checkSmartTileExists = async (orgPath: string, type: SmartTileType) => {
  return getData('AppAPI', generateRequestURL(`tiles/${orgPath}/tile/${workplaceID}/children`, { type, limit: 1, offset: 0 })).then(
    (res: APIListResponse<TileType>) => res.itemCount > 0,
  );
};
const generateSmartTile = async (orgPath: string, settings: { name: string; description: string; type: SmartTileType }) => {
  const payload = {
    ...settings,
    isEnabled: true,
    primarySource: 'hub',
  };
  return postData('AppAPI', `tiles/${orgPath}/tile/${workplaceID}/children`, payload);
};

export const setFeatureEnabledForOrg = async (values: {
  featureUUID: string;
  featureKey: string;
  orgPath: string;
  orgID: string;
  isEnabled: boolean;
}) => {
  const { featureUUID, featureKey, orgPath, orgID, isEnabled } = values;
  if (isEnabled) {
    if (featureKey === 'kudos') {
      const hasSmartTile = await checkSmartTileExists(orgPath, 'KUDOS');
      if (hasSmartTile === false) {
        await generateSmartTile(orgPath, { name: 'Kudos Tile', description: 'Kudos smart tile', type: 'KUDOS' });
      }
    }
    if (featureKey === 'journals') {
      const hasSmartTile = await checkSmartTileExists(orgPath, 'JOURNALS');
      if (hasSmartTile === false) {
        await generateSmartTile(orgPath, { name: 'Journals Tile', description: 'Journals smart tile', type: 'JOURNALS' });
      }
    }
    if (featureKey === 'learn') {
      const hasSmartTile = await checkSmartTileExists(orgPath, 'LEARN');
      if (hasSmartTile === false) {
        await generateSmartTile(orgPath, { name: 'Learn Tile', description: 'Learn smart tile', type: 'LEARN' });
      }
    }
    if (featureKey === 'inrehearsal') {
      if (orgPath === 'huler') return Promise.reject({ cause: 'sa-blocked' });

      // Needs to be importedContentID
      const importedTileChildren = await getTileChildrenByID(importedContentID);
      const inrehearsalTile = importedTileChildren.items.find((item: TileType) => item.name === 'Inrehearsal');
      await postData('TilesAPI', `${inrehearsalTile?.id}/importContent`, { organisationID: [orgID] });
    }
  } else {
    if (featureKey === 'inrehearsal') {
      const importedTiles = await getData('AppAPI', `tiles/${orgPath}/tile/${learnContentID}/children?isImportedContent=true`);
      await deleteData('AppAPI', `tiles/${orgPath}`, { tilesID: importedTiles.items.map((item: TileType) => item.id) });
    }
  }
  return patchData('AppAPI', `features/${orgPath}/features/${featureUUID}`, { isEnabled });
};

export const getOrgFeatures = (orgPath: string, params: PaginationQueryParams & { isEnabled: boolean }) => {
  return getData('AppAPI', generateRequestURL(`/features/${orgPath}/features`, params));
};

export const getUsersActiveFeatures = (userUUID: string): Promise<APIListResponse<{ key: string }>> => {
  return getData('FeaturesAPI', generateRequestURL(`user/${userUUID}`, { limit: maxInteger, offset: 0 }));
};

export const getFeaturesOrgs = async (data: FeatureFlagType[]) => {
  const composite = await compositeData(
    'CompositeFeaturesAPI',
    data.map((feature) => getOrgsWithFeatureCompositeObj(feature.id, { limit: 0, offset: 0 })),
  );

  return convertCompositeResponseToObject(composite);
};
